.justified-text {
    text-align: left;
    text-justify: inter-word;
    word-wrap: break-word; /* Ensures long words don't overflow */
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px; /* Default size */
    padding: 10px;
}

.img-text {
    color: green;
    text-decoration: underline;
    cursor: pointer;
}

.centered-text {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image-row {
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers items along the main axis */
    align-items: center; /* Align items along the cross-axis */
}

.half-size-image {
    width: 50%;
    height: auto;
}

.centered-image {
    display: block; /* Make image block to behave correctly in flexbox */
    margin: 20px; /* Adds margin around images for spacing */
    margin-left: auto; /* Auto margin for left */
    margin-right: auto; /* Auto margin for right */
    max-width: 100%; /* Ensures image does not overflow its container */
    border-radius: 5px; /* Rounded edges */
}

td {
    padding-left: 10px;
    padding-right: 10px;
}

.ul-top {
    margin-left: 30px;
}

.thumbnail {
    width: 100px;
    max-height: 200px;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.math-equation {
    justify-content: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
    font-style: oblique;
}

.custom-close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 10px;
    font-weight: bold;
    color: darkblue;
    text-shadow: none;
    opacity: 1;
    background-color: #fff;
    border: 2px solid darkblue;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.custom-close:hover, .custom-close:focus {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
}

.modal-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

/* Media query for mobile devices */
@media (max-width: 1200px) {
    .justified-text {
        font-size: 12px;
    }
}
