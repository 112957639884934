img {
    max-width: 100%;
    height: auto;
    border-radius: 30px; /* Rounded edges */
  }  

.img-center {
    display: flex;
    justify-content: center;
    padding: 30px;
}
