.footer-class {
    display: flex;
    justify-content: space-between; /* Distributes space between elements */
    align-items: center; /* Aligns items vertically */
    padding: 5px; /* Add padding for spacing */
    color: lightblue;
    border-radius: 20px;
    max-width: 61%; /* Set the width of the footer */
    margin: 0 auto; /* Center the footer horizontally */
}

.footer-btn {
    background-color: #a2cbf5; /* A muted light blue */
    color: #f8f9fa; /* Light color for text */
    border: none;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.footer-btn:hover {
    background-color: #5a6268; /* Slightly darker on hover */
}

/* Media query for mobile devices */
@media (max-width: 1200px) {
    footer.footer-class {
        max-width: 100%; /* On smaller screens, make the footer full-width */
    }

    .sign-petition-link {
        font-size: 1.5em;
    }

    .text-muted {
        font-size: 0.75em;
    }
}