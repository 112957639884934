.hero-content .img-fixed-height {
    height: 380px;
    width: auto;
    object-fit: contain; /* Keeps the aspect ratio intact */
    margin: 10px; /* Adds spacing between images */
  }

.centered-title {
    text-align: center; /* Aligns text to the center */
    width: 100%; /* Ensures the heading takes full width */
    margin: 0 auto; /* Centers the heading block horizontally */
}

.img-fluid {
  cursor: pointer;
}

/* Styles for iPad (768px to 1368px) */
@media screen and (min-width: 768px) and (max-width: 1368px) {
  .hero-content .img-fixed-height {
    height: 150px;
    max-width: 120px;
    margin: 2px;
  }
  .container {
    padding: 10px;
  }
  .header, .footer {
    font-size: 10px;
  }
}

/* Styles for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .hero-content .img-fixed-height {
    height: 90px;
    max-width: 120px;
    margin: 2px;
  }
  .container {
    padding: 10px;
  }
  .header, .footer {
    font-size: 10px;
  }
}