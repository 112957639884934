html, body {
  height: 100%;
  margin: 0; /* reset */
  padding: 0; /* reset */
}

p {
  margin: 0; /* reset */
  padding: 0; /* reset */
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  max-width: 600px; /* Set a max width for the box */
  margin: 0 auto; /* Center the box */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #fff; /* Optional: background color */
  border-radius: 20px;
}

.contact h1, .contact p, .contact form {
  color: #000; /* Ensuring text is black in light mode */
  text-align: left; /* Align text to the left */
}

.contact form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.contact form input, .contact form textarea, .contact form button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
}

/* Adjustments for smaller screens */
@media (max-width: 1200px) {
  .contact form {
    flex-direction: column;
  }

  .contact form input, .contact form textarea {
    padding: 8px;
    margin: 4px 0;
  }

  .contact form button {
    padding: 12px;
    font-size: 1em; /* Adjust font size if needed */
  }
}
